<template>
  <div>
    <section role="main" class="empty">
      <div class="container">
        <div class="row icon-tabs">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <router-link to="/executive" class="nav-link"
                ><img src="/img/icons/executives.png" alt="Executives" />
                Executives</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/company" class="nav-link"
                ><img src="/img/icons/companies.png" alt="Companies" />
                Companies</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/news" class="nav-link active"
                ><img src="/img/icons/deals.png" alt="News" /> News</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/deals-transactions" class="nav-link"
                ><img src="/img/icons/deals.png" alt="Deals & Transactions" />
                Deals & Transactions</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/listing" class="nav-link"
                ><img src="/img/book.png" style="height: 23px" />
                Listing</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/commentaries" class="nav-link"
                ><img src="/img/icons/commentaries.png" alt="Commentaries" />
                Commentaries</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/interesting-bites" class="nav-link"
                ><img
                  src="/img/icons/interesting.png"
                  alt="Interesting Bites"
                />
                Interesting Bites</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/open-positons-new" class="nav-link"
                ><img src="/img/icons/position.png" alt="Open Position" /> Open
                Positons</router-link
              >
            </li>
            <li class="">
              <a
                class="nav-link"
                style="
                  font-weight: bold !important;
                  margin-top: 8px;
                  font-size: 13px;
                  color: #1f3342;
                "
              >
                {{ count }}</a
              >
            </li>
          </ul>
          <div id="thirdmenu" class="tab-content mb-3">
            <div class="container px-0">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <!-- <form class="form-search form-inline"> -->
                  <input
                    type="text"
                    class="search-query"
                    style="border: 0px none; width: 100%"
                    v-on:keyup.enter="getList(0)"
                    v-model="searchObj.keyword"
                    placeholder="Search..."
                  />
                  <!-- </form> -->
                </div>
              </div>
              <hr class="mt-0 mb-2" />
            </div>
            <div class="row">
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">News Type</label>
                <Multiselect
                  mode="single"
                  trackBy="lable"
                  label="value"
                  valueProp="lable"
                  v-model="searchObj.type"
                  :options="types"
                  searchable="true"
                  placeholder="Select"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company</label>
                <Multiselect
                  mode="single"
                  trackBy="company_name"
                  label="company_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.company_id"
                  :options="companyList"
                  :noOptionsText="''"
                  searchable="true"
                  v-on:change="geteComponydetail($event)"
                  @search-change="searchCompanyName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Industry</label>
                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.industry_id"
                  :options="industryList"
                  searchable="true"
                  placeholder="Select"
                  @change="closesub($event)"
                  @input="onIndustryChange"
                  @search-change="searchIndustryName($event)"
                />
              </div>
              <div v-if="isShowSubsector" class="col-lg-2 col-sm-4 col-6">
                <label class="label">Subsector</label>

                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.subsector_id"
                  :options="subsectorList"
                  searchable="true"
                  placeholder="Select"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Job Function</label>
                <Multiselect
                  mode="single"
                  trackBy="job_function_name"
                  label="job_function_name"
                  valueProp="id"
                  v-model="searchObj.job_function_id_new"
                  :options="jobFunctionList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchJobFunctionName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6" v-if="!isShowSubsector">
                <label class="label">Deal Stage</label>
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  v-model="searchObj.deal_stage_id"
                  :options="dealStageList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchDealStage($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <!-- <label></label> -->
                <button
                  type="button"
                  style="margin-top: 23px; width: 50%"
                  v-on:click="getList(0)"
                  class="apply-btn mr-1"
                >
                  Apply
                </button>
                <button
                  v-on:click="resetFilter()"
                  type="button"
                  style="margin-top: 23px; width: 40%"
                  class="apply-btn"
                >
                  Reset
                </button>
                <div class="text-center">
                  <a
                    style="cursor: pointer"
                    v-on:click="getAdvanceSearch()"
                    class="advance-search"
                    >{{ is_show ? "Hide Advance Search" : "Advance Search" }}</a
                  >
                </div>
                <!--  <div class="text-center"><a href="#" class="advance-search">Advance Search</a></div> -->
              </div>
            </div>
            <div v-show="is_show" class="row advasearch-data mt-1">
              <div class="col-lg-2 col-sm-4 col-6" v-if="isShowSubsector">
                <label class="label">Deal Stage</label>
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  v-model="searchObj.deal_stage_id"
                  :options="dealStageList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchDealStage($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">From Date</label>
                <datepicker
                  v-model="searchObj.from_date"
                  inputFormat="dd/MM/yyyy"
                  placeholder="From Date"
                  class="form-control"
                  style="background-color: white !important; height: 35px"
                  :clearable="false"
                  :upperLimit="searchObj.to_date ? searchObj.to_date : new Date()"
                  @click="onClickFromDate()"
                >
                </datepicker>
              </div>              
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">To Date</label>
                <datepicker
                  v-model="searchObj.to_date"
                  inputFormat="dd/MM/yyyy"
                  class="form-control"
                  placeholder="To Date"
                  :clearable="false"
                  style="background-color: white !important; height: 35px"
                  :lowerLimit="searchObj.from_date ? searchObj.from_date : null"
                  :upperLimit="new Date()"
                  @click="onClickFromDate()"
                >
                </datepicker>
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Date Period</label>
                <Multiselect
                  mode="single"
                  trackBy="value"
                  label="value"
                  valueProp="id"
                  v-model="searchObj.date_period"
                  :options="date_periods"
                  searchable="true"
                  placeholder="Select"
                  @change="dateFilter($event)"
                  @click ="onClickDatePeriod()"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Location</label>
                <Multiselect
                  mode="single"
                  trackBy="country_name"
                  label="country_name"
                  valueProp="id"
                  v-model="searchObj.country_id"
                  :options="cityList"
                  :noOptionsText="''"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchCityName($event)"
                />
              </div>

              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Region</label>
                <Multiselect
                  mode="single"
                  trackBy="region_name"
                  label="region_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.region_id_new"
                  :options="regionList"
                  searchable="true"
                  @search-change="searchRegionName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6 mt-2">
                <label class="label">Company Type</label>
                <Multiselect
                  mode="single"
                  trackBy="value"
                  label="value"
                  valueProp="id"
                  v-model="searchObj.company_type"
                  :options="company_types"
                  searchable="true"
                  placeholder="Select"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6 mt-2">
                <label class="label">Past Company</label>
                <Multiselect
                  mode="single"
                  trackBy="company_name"
                  label="company_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.prev_company_id"
                  :options="companyList"
                  :noOptionsText="''"
                  searchable="true"
                  @search-change="searchCompanyName($event)"
                />
              </div>
            </div>
            <hr />
            <div
              class="row mt-4"
              style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
              v-for="(data, index) in list"
              :key="index"
            >
              <div class="col-lg-2 col-sm-2 text-center">
                <a
                  style="cursor: pointer"
                  v-on:click="goToView('/company-detail/' + data.company_id)"
                >
                  <img
                    :title="data.company_name"
                    :src="
                      data.company_logo
                        ? data.company_logo
                        : '/img/user-pic.png'
                    "
                    alt=""
                    width="40"
                    height="40"
                  />
                  <!-- class="md-size" -->
                </a>
                <br />
                <a
                  :title="
                    data.country_code ? data.country_code.country_name : ''
                  "
                >
                  <country-flag
                    :country="
                      data.country_code && data.country_code.country_code
                        ? data.country_code.country_code
                        : '-'
                    "
                  />
                </a>
                <br />
                <span class="update-date"
                  >Published Date :
                  {{ $helperService.getFormatedDate(data.news_date) }}</span
                >
              </div>
              <div class="col-lg-8 col-sm-5 pb-3 txt-center">
                <p class="clrb" style="padding: 0; margin: 0">
                  {{ data.news_title }}
                </p>

                <div class="col-12 row">
                  <div class="col-6 mt-3 row">
                    <div
                      v-for="(data1, index1) in data.news_mention_executives"
                      :key="index1"
                    >
                      <div v-if="index1 <= 4">
                        <img
                          v-if="data1.executive_logo || !data1.gender"
                          style="
                            cursor: pointer;
                            border: 1px solid white; /*! padding: 0.1px; */
                            box-shadow: 18px;
                            width: 37px !important;
                            box-shadow: 0px 0px 0.5px 0.5px GREY;
                          "
                          :title="data1.executive_name"
                          v-on:click="
                            goToView('/executive-detail/' + data1.executives_id)
                          "
                          :src="
                            data1.executive_logo
                              ? data1.executive_logo
                              : '/img/new.png'
                          "
                          width="50"
                          height="50"
                          :style="index1 == 0 ? 'width: 31px;' : 'width: 37px;'"
                          :class="index1 == 0 ? 'sm-img' : 'sm-img minus8'"
                        />
                        <img
                          v-on:click="
                            goToView('/executive-detail/' + data1.executives_id)
                          "
                          style="
                            cursor: pointer;
                            border: 1px solid white; /*! padding: 0.1px; */
                            box-shadow: 18px;
                            width: 37px !important;
                            box-shadow: 0px 0px 0.5px 0.5px GREY;
                          "
                          :title="data1.executive_name"
                          v-if="data1.gender == 'male' && !data1.executive_logo"
                          :src="
                            data1.executive_logo
                              ? data1.executive_logo
                              : '/img/male.png'
                          "
                          width="50"
                          height="50"
                          :class="index1 == 0 ? 'sm-img' : 'sm-img minus8'"
                        />
                        <img
                          v-on:click="
                            goToView('/executive-detail/' + data1.executives_id)
                          "
                          style="cursor: pointer"
                          :title="data1.executive_name"
                          v-if="
                            data1.gender == 'female' && !data1.executive_logo
                          "
                          :src="
                            data1.executive_logo
                              ? data1.executive_logo
                              : '/img/female.png'
                          "
                          width="50"
                          height="50"
                          :style="index1 == 0 ? 'width: 31px;' : 'width: 37px;'"
                          :class="index1 == 0 ? 'sm-img' : 'sm-img minus8'"
                        />
                      </div>
                    </div>
                    <!-- sm-img minus8 -->
                  </div>
                  <div class="col-6 mt-3 row">
                    <div
                      v-for="(data2, index2) in data.news_mention_company"
                      :key="index2"
                    >
                      <div
                        v-if="
                          index2 <= 5 && data.company_id != data2.company_id
                        "
                      >
                        <img
                          style="
                            cursor: pointer;
                            border: 1px solid white; /*! padding: 0.1px; */
                            box-shadow: 18px;
                            width: 37px !important;
                            box-shadow: 0px 0px 0.5px 0.5px GREY;
                          "
                          :title="data2.company_name"
                          v-on:click="
                            goToView('/company-detail/' + data2.company_id)
                          "
                          :src="data2.company_logo"
                          width="30"
                          height="30"
                          :style="index2 == 0 ? 'width: 31px;' : 'width: 37px;'"
                          :class="index2 == 0 ? 'sm-img' : 'sm-img minus8'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="data.is_company_logo == 1"
                class="col-lg-1 col-sm-2 col-6 text-center"
                style="cursor: pointer"
                v-on:click="openLink(data.news_link)"
              >
                <img
                  :title="data.company_name ? data.company_name : ''"
                  :src="data.company_logo ? data.company_logo : '/img/new.png'"
                  alt=""
                  height="60"
                  width="150"
                />
              </div>
              <div
                v-if="data.is_company_logo == 0"
                v-on:click="openLink(data.news_link)"
                style="cursor: pointer"
                class="col-lg-1 col-sm-2 col-6 text-center"
              >
                <img
                  :title="
                    data.publication && data.publication.name
                      ? data.publication.name
                      : ''
                  "
                  :src="
                    data.publication && data.publication.publication_logo
                      ? data.publication.publication_logo
                      : ''
                  "
                  alt=""
                  height="60"
                  width="150"
                />
              </div>
            </div>

            <!-- <hr /> -->
            <div class="row mx-0 mb-2">
              <div class="col-lg-12 mt-4 text-center">
                <div class="page-bottom-pagination text-right">
                  <Pagination
                    @page-change="pageChange"
                    @items-per-page-change="itemsPerPageChange"
                    ref="listPagination"
                  />
                </div>
              </div>
            </div>
            <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// import { Field } from "vee-validate";
// import Datepicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import CountryFlag from "vue-country-flag-next";
import datepicker from "vue3-datepicker";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
export default {
  name: "News",
  components: {
    ErrorComponent,
    Pagination,
    CountryFlag,
    datepicker,
    // Field,
    Multiselect,
  },
  data() {
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      count: 0,
      loader: true,
      is_show: false,
      isShowSubsector: false,
      company_types: [
        "Public",
        "Private",
        "Education",
        "Government",
        "Not-for-Profit",
      ],
      date_periods: [
        "Last Month",
        "Last 3 Month",
        "Last 6 Month",
        "Last 1 Year",
      ],      
      types: [
        {
          value: "Arrival / Departure",
          lable: "arrival_departure",
        },
        {
          value: "Internal Move",
          lable: "internal_move",
        },
        {
          value: "Lay Off",
          lable: "lay_off",
        },
        {
          value: "Own Venture",
          lable: "own_venture",
        },
        {
          value: "Terminated",
          lable: "terminated",
        },
      ],
      searchObj: {
        type: [],
        from_date: "",
        to_date: "",
        country_id: "",
        company_type: "",
        job_function_id_new: "",
        region_id_new: "",
        prev_company_id: "",
        subsector_id: "",
        industry_id: [],
        company_id: [],
        deal_stage_id: [],
        date_period: "",
      },
      dealStageList: [],
      industryList: [],
      subsectorList: [],
      companyList: [],
      company_ids: [],
      cityList: [],
      regionList: [],
      jobFunctionList: [],
      cityKeyword: "",
      offset: -1,
      list: [],
      companyKeyword: "",
      industryKeyword: "",
      newsTypeKeyword: "",
      jobFunctionKeyword: "",
      regionKeyword: "",
      dealStageKeyword: "",
      subsectorKeyword: "",
    };
  },
  mounted() {
    if (this.id || this.type) {
      this.getExecutiveDetail(this.id, this.type);
    } else {
      this.getList(0);
      this.jobFunctionlist();
      this.getRegionlist();
    }
    this.industrylist();
    this.getDealStageList();
  },
  methods: {
    getAdvanceSearch() {
      this.is_show = !this.is_show;
    },
    searchCityName(e) {
        this.cityKeyword = e;
        this.CityList();
    },
    searchRegionName(e) {
      this.regionKeyword = e;
      this.getRegionlist();
    },
    searchJobFunctionName(e) {
      this.jobFunctionKeyword = e;
      this.jobFunctionlist();
    },
    searchDealStage(e) {
      this.dealStageKeyword = e;
      this.getDealStageList();
    },
    getRegionlist() {
      var obj = {};
      if (this.regionKeyword) {
        obj.keyword = this.regionKeyword;
      }
      this.$api
        .getAPI({
          _action: "/region/list",
          _body: obj,
        })
        .then((res) => {
          this.regionList = res;
        })
        .catch(() => {});
    },
    jobFunctionlist() {
      var obj = {};
      if (this.jobFunctionKeyword) {
        obj.keyword = this.jobFunctionKeyword;
      }
      this.$api
        .getAPI({
          _action: "/job/function/list",
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    CityList() {
      var obj = {};
      if (this.cityKeyword) {
        obj.keyword = this.cityKeyword;
      }
      this.$api
        .getAPI({
          _action: "/country/list",
          _body: obj,
        })
        .then((res) => {
          if (this.cityKeyword) {
            this.cityList = res;
          } else {
            this.cityList = [];
          }
        })
        .catch(() => {});
    },
    getDealStageList() {
      this.$api
        .getAPI({
          _action: "/deal/stage/list",
        })
        .then((res) => {
          this.dealStageList = res;
        })
        .catch(() => {});
    },
    resetFilter() {
      this.searchObj = {};
      this.searchObj.type = "";
      this.searchObj.from_date = "";
      this.searchObj.to_date = "";
      this.searchObj.industry_id = "";
      this.searchObj.company_id = "";
      this.searchObj.country_id = "";
      this.searchObj.company_type = "";
      this.searchObj.date_period = "";
      this.searchObj.job_function_id_new = "";
      this.searchObj.region_id_new = "";
      this.searchObj.deal_stage_id = "";
      this.searchObj.subsector_id = "";
      this.isShowSubsector = false;
      this.getList(0);
    },
    getExecutiveDetail(id, type) {
      if (id && type) {
        this.searchObj.executive_id = id;
      } else {
        this.searchObj.company_id = id;
        this.company_ids.push(id);
      }
      this.companylist();
      this.getList(0);
    },
    openLink(url) {
      window.open(url);
    },
    searchNewsType(e) {
      this.newsTypeKeyword = e;
      this.industrylist();
    },
    searchIndustryName(e) {
      this.industryKeyword = e;
      this.industrylist();
    },
    searchCompanyName(e) {
        this.companyKeyword = e;
        this.companylist();
    },
    companylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids) {
        obj.company_ids = this.company_ids;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          if (this.companyKeyword || this.company_ids?.length > 0) {
            this.companyList = res;
          } else {
            this.companyList = [];
          }
        })
        .catch(() => {});
    },
    closesub(e) {
      if (!e) {
        this.searchObj.subsector_id = "";
      }
    },
    onIndustryChange(selectedIndustryId) {
      if (selectedIndustryId) {
        this.subsectorList = [];
        this.isShowSubsector = false;
        this.getSubsectorList(selectedIndustryId);
      } else {
        this.isShowSubsector = false;
      }
    },
    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    getSubsectorList(id) {
      this.$api
        .getAPI({
          _action: "/subsectors/list/" + id,
        })
        .then((res) => {
          this.subsectorList = res.list;

          // Only show subsector dropdown if there are subsectors(of industry) available
          if (this.subsectorList.length > 0) {
            this.isShowSubsector = true;
            this.is_show = true;
          } else {
            this.isShowSubsector = false;
          }
        })
        .catch(() => {
          this.isShowSubsector = false;
        });
    },
    geteComponydetail(id) {
      if (id) {
        this.$api
          .getAPI({
            _action: "/company/detail/" + id,
          })

          .then((res) => {
            this.searchObj.industry_id = res.industry_id;
          })
          .catch(() => {});
      }
    },
    onClickFromDate() {
      this.searchObj.date_period = "";
    },
    dateFilter(e) {
      if (!e) {
        this.searchObj.date_period = '';
      }
    },
    onClickDatePeriod() {
      this.searchObj.from_date = "";
      this.searchObj.to_date = "";
      this.searchObj.start_date = "";
      this.searchObj.end_date = "";
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    goToView(url) {
      this.searchObj.is_show = this.is_show;
      this.$shareService.setMapValue("news_page_filter", this.searchObj);
      this.$router.push(url);
    },
    getList(page) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      if (this.searchObj.from_date) {
        this.searchObj.start_date = moment(this.searchObj.from_date).format(
          "DD-MM-YYYY"
        );
      }
      if (this.searchObj.to_date) {
        this.searchObj.end_date = moment(this.searchObj.to_date).format(
          "DD-MM-YYYY"
        );
      }
      if (!this.searchObj.date_period) {
        this.searchObj.date_period = "";
      }
      if (!this.searchObj.job_function_id_new) {
        this.searchObj.job_function_id_new = "";
      }
      if (!this.searchObj.job_function_id_new) {
        this.searchObj.job_function_id_new = "";
      }
      if (!this.searchObj.region_id_new) {
        this.searchObj.region_id_new = "";
      }
      if (!this.searchObj.type) {
        this.searchObj.type = "";
      }
      if (!this.searchObj.country_id) {
        this.searchObj.country_id = "";
      }
      if (!this.searchObj.company_type) {
        this.searchObj.company_type = "";
      }
      if (!this.searchObj.keyword) {
        this.searchObj.keyword = "";
      }
      if (!this.searchObj.industry_id) {
        this.searchObj.industry_id = "";
      }
      if (!this.searchObj.company_id) {
        this.searchObj.company_id = "";
      }
      if (this.offset != page) {
        this.searchObj.offset = page;
      } else {
        this.searchObj.offset = page;
      }
      var filter_obj = this.$shareService.getMapValue("news_page_filter")
        ? this.$shareService.getMapValue("news_page_filter")
        : null;
      if (filter_obj) {
        this.searchObj = filter_obj;
        this.is_show = this.searchObj.is_show;
        this.$shareService.getMapValueAndRemove("news_page_filter");
        if (this.searchObj.company_id) {
          this.company_ids.push(this.searchObj.company_id);
          this.companylist();
        }
      }
      this.$api
        .getAPI({
          _action: "/news/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.count = res.count;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
                this.$refs.listPagination.setCurrentPage(
                  this.searchObj.offset + 1
                );
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No news list available");
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: #f0f0f0;
  height: 35px;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>
