<template>
  <div>
    <section role="main" class="empty">
      <div class="container">
        <div class="row icon-tabs">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <router-link to="/executive" class="nav-link"
                ><img src="/img/icons/executives.png" alt="Executives" />
                Executives</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/company" class="nav-link"
                ><img src="/img/icons/companies.png" alt="Companies" />
                Companies</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/news" class="nav-link"
                ><img src="/img/icons/deals.png" alt="News" /> News</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/deals-transactions" class="nav-link"
                ><img src="/img/icons/deals.png" alt="Deals & Transactions" />
                Deals & Transactions</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/listing" class="nav-link"
                ><img src="/img/book.png" style="height: 23px" />
                Listing</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/commentaries" class="nav-link"
                ><img src="/img/icons/commentaries.png" alt="Commentaries" />
                Commentaries</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/interesting-bites" class="nav-link"
                ><img
                  src="/img/icons/interesting.png"
                  alt="Interesting Bites"
                />
                Interesting Bites</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/open-positons-new" class="nav-link active"
                ><img src="/img/icons/position.png" alt="Open Position" /> Open
                Positons</router-link
              >
            </li>
            <li class="">
              <a
                class="nav-link"
                style="
                  font-weight: bold !important;
                  margin-top: 8px;
                  font-size: 13px;
                  color: #1f3342;
                "
              >
                {{ count }}</a
              >
            </li>
          </ul>
          <div id="fivethmenu" class="tab-content mb-3">
            <div class="container px-0">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <input
                    type="text"
                    class="search-query"
                    style="border: 0px none; width: 100%"
                    placeholder="Search..."
                    v-on:keyup.enter="getList(0)"
                    v-model="searchObj.keyword"
                  />
                </div>
              </div>
              <hr class="mt-0 mb-2" />
            </div>
            <div class="row">
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company</label>
                <Multiselect
                  mode="single"
                  trackBy="company_name"
                  label="company_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.company_id"
                  :options="companyList"
                  :noOptionsText="''"
                  searchable="true"
                  @search-change="searchCompanyName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company Type</label>
                <Multiselect
                  mode="single"
                  trackBy="value"
                  label="value"
                  valueProp="id"
                  v-model="searchObj.company_type"
                  :options="company_types"
                  searchable="true"
                  placeholder="Select Type"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Company Size</label>
                <Multiselect
                  mode="single"
                  trackBy="value"
                  label="value"
                  valueProp="id"
                  v-model="searchObj.company_size"
                  :options="company_sizes"
                  searchable="true"
                  placeholder="Select Size"
                />
              </div>
              <!-- <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Select Topic</label>
                <Multiselect
                  mode="single"
                  trackBy="topic_name"
                  label="topic_name"
                  valueProp="id"
                  v-model="searchObj.topic_id"
                  :options="topicList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchTopicName($event)"
                />
              </div> -->
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Job Function</label>
                <Multiselect
                  mode="single"
                  trackBy="job_function_name"
                  label="job_function_name"
                  valueProp="id"
                  v-model="searchObj.job_function_id_new"
                  :options="jobFunctionList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchJobFunctionName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Location</label>
                <Multiselect
                  mode="single"
                  trackBy="country_name"
                  label="country_name"
                  valueProp="id"
                  v-model="searchObj.country_id"
                  :options="cityList"
                  :noOptionsText="''"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchCityName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <!-- <label></label> -->
                <button
                  type="button"
                  style="margin-top: 23px; width: 50%"
                  v-on:click="getList(0)"
                  class="apply-btn mr-1"
                >
                  Apply
                </button>
                <button
                  v-on:click="resetFilter()"
                  type="button"
                  style="margin-top: 23px; width: 40%"
                  class="apply-btn"
                >
                  Reset
                </button>
                <!--  <div class="text-center"><a href="#" class="advance-search">Advance Search</a></div> -->
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2 col-sm-4 col-6 mt-2">
                <label class="label">From Date</label>

                <datepicker
                  v-model="searchObj.from_date"
                  inputFormat="dd/MM/yyyy"
                  placeholder="To Date"
                  class="form-control"
                  style="background-color: white !important; height: 35px"
                  :clearable="false"
                  :upperLimit="searchObj.to_date ? searchObj.to_date : null"
                  @click="onClickFromDate()"
                >
                </datepicker>
              </div>
              <div class="col-lg-2 col-sm-4 col-6 mt-2">
                <label class="label">To Date</label>
                <datepicker
                  v-model="searchObj.to_date"
                  inputFormat="dd/MM/yyyy"
                  class="form-control"
                  placeholder="To Date"
                  :clearable="false"
                  style="background-color: white !important; height: 35px"
                  :lowerLimit="searchObj.from_date ? searchObj.from_date : null"
                  @click="onClickFromDate()"
                >
                </datepicker>
              </div>
              <div class="col-lg-2 col-sm-4 col-6 mt-2">
                <label class="label">Date Period</label>
                <Multiselect
                  mode="single"
                  trackBy="value"
                  label="value"
                  valueProp="id"
                  v-model="searchObj.date_period"
                  :options="date_periods"
                  searchable="true"
                  placeholder="Select"
                  @change="dateFilter($event)"
                  @click="onClickDatePeriod()"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6 mt-2">
                <label class="label">Seniority Level</label>
                <Multiselect
                  mode="single"
                  trackBy="seniority_name"
                  label="seniority_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.seniority_id"
                  :options="seniorityList"
                  searchable="true"
                  @search-change="searchSeniorityName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6 mt-2">
                <label class="label">Industry</label>
                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.industry_id"
                  :options="industryList"
                  searchable="true"
                  placeholder="Select"
                  @change="closesub($event)"
                  @input="onIndustryChange"
                  @search-change="searchIndustryName($event)"
                />
              </div>
              <div v-if="isShowSubsector" class="col-lg-2 col-sm-4 col-6 mt-2">
                <label class="label">Subsector</label>

                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.subsector_id"
                  :options="subsectorList"
                  searchable="true"
                  placeholder="Select"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6 mt-2">
                <label class="label">Deal Stage</label>
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  v-model="searchObj.deal_stage_id"
                  :options="dealStageList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchDealStage($event)"
                />
              </div>
            </div>
            <hr />

            <div id="fourthmenu" class="tab-content mb-3">
              <div class="col-12 row">
                <div
                  class="col-lg-4 col-sm-4"
                  v-for="(data, index) in list"
                  :key="index"
                >
                  <div class="rectangle-box">
                    <div class="row" style="height: 115px">
                      <div class="col-lg-3 col-sm-3 col-1 pr-2 text-center">
                        <a
                          v-on:click="
                            $router.push('/company-detail/' + data.company_id)
                          "
                        >
                          <img
                            :title="
                              data.company1 && data.company1.company_name
                                ? data.company1.company_name
                                : ''
                            "
                            :src="
                              data.company1 && data.company1.company_logo
                                ? data.company1.company_logo
                                : '/img/user-pic.png'
                            "
                            alt=""
                            class="md-size"
                            style="cursor: pointer"
                          />
                        </a>

                        <a
                          style="cursor: pointer"
                          :title="
                            data.country1 && data.country1.country_name
                              ? data.country1.country_name
                              : ''
                          "
                        >
                          <country-flag
                            :country="
                              data.country1 && data.country1.country_code
                                ? data.country1.country_code
                                : ''
                            "
                          />
                        </a>
                      </div>
                      <div class="col-lg-7 col-sm-7 col-9 px-2">
                        <div
                          v-on:click="
                            $router.push('/company-detail/' + data.company_id)
                          "
                          style="display: initial !important; cursor: pointer"
                          :title="
                            data.company1.company_name
                              ? data.company1.company_name
                              : '-'
                          "
                          class="client-name"
                        >
                          {{
                            data.company1.company_name
                              ? data.company1.company_name
                              : "-"
                          }}
                        </div>
                        <!-- <p style="cursor: pointer; font-size: 15px">
                          {{ data.title ? data.title : "-" }}
                        </p> -->
                        <!-- <a target="_blank" style="text-decoration: none"> -->
                        <p
                          v-on:click="
                            data.job_description_url
                              ? window.open(data.job_description_url)
                              : ''
                          "
                          style="
                            cursor: pointer;
                            font-size: 11px;
                            line-height: 1.8;
                          "
                        >
                          {{
                            data.title
                              ? $helperService.getText(data.title, 50)
                              : "-"
                          }}
                        </p>
                        <!-- </a> -->
                      </div>
                      <div
                        class="col-lg-7 col-sm-7 col-9 px-2"
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          gap: 10px;
                        "
                      >
                        <a
                          v-if="data && data.linkdin_url"
                          :href="data.linkdin_url"
                          target="_blank"
                          style="display: inline-block; cursor: pointer"
                        >
                          <img
                            :title="data.linkdin_url ? data.linkdin_url : ''"
                            src="img/linkdin_icon.png"
                            alt="LinkedIn"
                            style="width: 24px; height: 24px"
                          />
                        </a>
                        <a
                        v-if="data && data.career_page_url"
                          :href="data.career_page_url"
                          target="_blank"
                          style="display: inline-block; cursor: pointer"
                        >
                          <img
                            :title="
                              data.career_page_url ? data.career_page_url : ''
                            "
                            src="img/internet_icon.png"
                            alt="Career Page"
                            style="width: 24px; height: 24px"
                          />
                        </a>
                      </div>
                    </div>

                    <div class="col-lg-12 col-sm-12">
                      <span class="update-date"
                        >Published Date :
                        {{
                          $helperService.getFormatedDate(data.created_at)
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mx-0 mb-2">
                <div class="col-lg-12 mt-3 text-center">
                  <div class="page-bottom-pagination text-right">
                    <Pagination
                      @page-change="pageChange"
                      @items-per-page-change="itemsPerPageChange"
                      ref="listPagination"
                    />
                  </div>
                </div>
              </div>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// import { Field } from "vee-validate";
import datepicker from "vue3-datepicker";
import Multiselect from "@vueform/multiselect";
// import Datepicker from "../src/datepicker/Datepicker.vue";
// import "@vuepic/vue-datepicker/dist/main.css";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import CountryFlag from "vue-country-flag-next";
import moment from "moment";
export default {
  name: "News",
  components: {
    ErrorComponent,
    Pagination,
    CountryFlag,
    // Field,
    datepicker,
    Multiselect,
  },
  data() {
    return {
      loader: true,
      isShowSubsector: false,
      picked: new Date(),
      count: 0,
      company_types: [
        "Public",
        "Private",
        "Education",
        "Government",
        "Not-for-Profit",
      ],
      company_sizes: [
        "1-10",
        "11-50",
        "51-200",
        "201-500",
        "501-1000",
        "1001-5000",
        "5001-10000",
        "10000+",
      ],
      date_periods: [
        "Last Month",
        "Last 3 Month",
        "Last 6 Month",
        "Last 1 Year",
      ],
      searchObj: {
        type: "",
        from_date: "",
        to_date: "",
        industry_id: "",
        company_id: "",
        keyword: "",
        job_function_id_new: "",
        country_id: "",
        seniority_id: "",
        company_size: "",
        company_type: "",
        deal_stage_id: "",
        subsector_id: "",
        date_period: "",
      },
      industryList: [],
      companyList: [],
      topicList: [],
      offset: -1,
      list: [],
      cityKeyword: "",
      companyKeyword: "",
      industryKeyword: "",
      topicKeyword: "",
      jobFunctionKeyword: "",
      jobFunctionList: [],
      cityList: [],
      seniorityList: [],
      dealStageList: [],
      dealStageKeyword: "",
      subsectorList: [],
      subsectorKeyword: "",
    };
  },
  mounted() {
    this.getList(0);
    this.industrylist();
    this.topiclist();
    this.jobFunctionlist();
    this.getSenioritylist();
  },
  methods: {
    resetFilter() {
      this.searchObj = {};
      this.searchObj.type = "";
      this.searchObj.keyword = "";
      this.searchObj.from_date = "";
      this.searchObj.to_date = "";
      this.searchObj.industry_id = "";
      this.searchObj.job_function_id_new = "";
      this.searchObj.topic_id = "";
      this.searchObj.company_id = "";
      this.searchObj.deal_stage_id = "";
      this.searchObj.subsector_id = "";
      this.isShowSubsector = false;
      this.searchObj.date_period = "";

      this.getList(0);
    },
    searchTopicName(e) {
      this.topicKeyword = e;
      this.topiclist();
    },
    searchIndustryName(e) {
      this.industryKeyword = e;
      this.industrylist();
    },
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    searchJobFunctionName(e) {
      this.jobFunctionKeyword = e;
      this.jobFunctionlist();
    },
    searchCityName(e) {
      this.cityKeyword = e;
      this.CityList();
    },
    searchSeniorityName(e) {
      this.seniorityKeyword = e;
      this.getSenioritylist();
    },
    searchDealStage(e) {
      this.dealStageKeyword = e;
      this.getDealStageList();
    },
    getSenioritylist() {
      var obj = {};
      if (this.seniorityKeyword) {
        obj.keyword = this.seniorityKeyword;
      }
      this.$api
        .getAPI({
          _action: "/seniority/list",
          _body: obj,
        })
        .then((res) => {
          this.seniorityList = res;
        })
        .catch(() => {});
    },
    getDealStageList() {
      this.$api
        .getAPI({
          _action: "/deal/stage/list",
        })
        .then((res) => {
          this.dealStageList = res;
        })
        .catch(() => {});
    },
    CityList() {
      var obj = {};
      if (this.cityKeyword) {
        obj.keyword = this.cityKeyword;
      }
      this.$api
        .getAPI({
          _action: "/country/list",
          _body: obj,
        })
        .then((res) => {
          if (this.cityKeyword) {
            this.cityList = res;
          } else {
            this.cityList = [];
          }
        })
        .catch(() => {});
    },
    companylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          if (this.companyKeyword) {
            this.companyList = res;
          } else {
            this.companyList = [];
          }
        })
        .catch(() => {});
    },
    geteComponydetail(id) {
      if (id) {
        this.$api
          .getAPI({
            _action: "/company/detail/" + id,
          })

          .then((res) => {
            this.searchObj.industry_id = res.industry_id;
          })
          .catch(() => {});
      }
    },
    topiclist() {
      var obj = {};
      if (this.topicKeyword) {
        obj.keyword = this.topicKeyword;
      }
      this.$api
        .getAPI({
          _action: "/topic/list",
          _body: obj,
        })
        .then((res) => {
          this.topicList = res;
        })
        .catch(() => {});
    },
    closesub(e) {
      if (!e) {
        this.searchObj.subsector_id = "";
      }
    },
    onIndustryChange(selectedIndustryId) {
      if (selectedIndustryId) {
        this.subsectorList = [];
        this.isShowSubsector = false;
        this.getSubsectorList(selectedIndustryId);
      } else {
        this.isShowSubsector = false;
      }
    },
    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    getSubsectorList(id) {
      this.$api
        .getAPI({
          _action: "/subsectors/list/" + id,
        })
        .then((res) => {
          this.subsectorList = res.list;

          // Only show subsector dropdown if there are subsectors(of industry) available
          if (this.subsectorList.length > 0) {
            this.isShowSubsector = true;
          } else {
            this.isShowSubsector = false;
          }
        })
        .catch(() => {
          this.isShowSubsector = false;
        });
    },
    jobFunctionlist() {
      var obj = {};
      if (this.jobFunctionKeyword) {
        obj.keyword = this.jobFunctionKeyword;
      }
      this.$api
        .getAPI({
          _action: "/job/function/list",
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    onClickFromDate() {
      this.searchObj.date_period = "";
    },
    dateFilter(e) {
      if (!e) {
        this.searchObj.date_period = '';
      }
    },
    onClickDatePeriod() {
      this.searchObj.from_date = "";
      this.searchObj.to_date = "";
    },
    getList(page) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (!this.searchObj.company_id) {
        this.searchObj.company_id = "";
      }
      if (!this.searchObj.industry_id) {
        this.searchObj.industry_id = "";
      }
      if (!this.searchObj.company_size) {
        this.searchObj.company_size = "";
      }
      if (!this.searchObj.seniority_id) {
        this.searchObj.seniority_id = "";
      }
      if (!this.searchObj.job_function_id_new) {
        this.searchObj.job_function_id_new = "";
      }
      if (!this.searchObj.country_id) {
        this.searchObj.country_id = "";
      }
      if (!this.searchObj.deal_stage_id) {
        this.searchObj.deal_stage_id = "";
      }
      if (!this.searchObj.subsector_id) {
        this.searchObj.subsector_id = "";
      }
      if (!this.searchObj.company_type) {
        this.searchObj.company_type = "";
      }
      var obj = {};
      obj.offset = this.searchObj.offset;
      obj.type = this.searchObj.type;
      if (this.searchObj.from_date) {
        obj.from_date = moment(this.searchObj.from_date).format("DD-MM-YYYY");
      }
      if (this.searchObj.to_date) {
        obj.to_date = moment(this.searchObj.to_date).format("DD-MM-YYYY");
      }
      if (this.searchObj.date_period) {
        obj.date_period = this.searchObj.date_period;
      }

      obj.industry_id = this.searchObj.industry_id;
      obj.company_id = this.searchObj.company_id;
      obj.company_type = this.searchObj.company_type;
      obj.company_size = this.searchObj.company_size;
      obj.country_id = this.searchObj.country_id;
      obj.job_function_id_new = this.searchObj.job_function_id_new;
      obj.seniority_id = this.searchObj.seniority_id;
      obj.keyword = this.searchObj.keyword;
      obj.deal_stage_id = this.searchObj.deal_stage_id;
      obj.subsector_id = this.searchObj.subsector_id;
      this.$api
        .getAPI({
          _action: "/open/position/pagination/list1",
          _body: obj,
        })
        .then((res) => {
          console.log(res);
          this.loader = false;
          this.list = res.list;
          this.count = res.count;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, 10);
              } else {
                this.$refs.listPagination.setTotalCount(0, 10);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No open position list available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
          this.$refs.errorComponent.updateListLoader(false);
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: #f0f0f0;
  height: 35px;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>
