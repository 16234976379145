<template>
  <div>
    <header>
      <nav class="navbar navbar-expand-md fixed-top">
        <div class="container">
          <a
            v-on:click="$router.push('/executive')"
            class="navbar-brand"
            style="cursor: pointer"
            ><img src="/img/logo.svg" alt="ExecLibrary" width="100" height="51"
          /></a>
          <button
            class="navbar-toggler px-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExampleDefault"
            aria-controls="navbarsExampleDefault"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <!--<span class="navbar-toggler-icon"></span> -->
            <img src="/img/menu.png" alt="" />
          </button>
          <div class="collapse navbar-collapse" id="navbarsExampleDefault">
            <ul class="navbar-nav ml-auto">
              <li v-show="token" class="nav-item active pt5">
                <a
                  class="nav-link"
                  style="cursor: pointer"
                  v-on:click="$router.push('/executive')"
                  >
                  <!-- <i class="fa fa-home" aria-hidden="true"></i> -->
                   Home</a
                >
              </li>
              <li class="nav-item active pt5">
                <a
                  class="nav-link"
                  style="cursor: pointer"
                  v-on:click="$router.push('/')"
                >
                  <!-- <img src="/img/icons/dashboard-icon-hv.png" /> -->
                  Dashboard</a
                >
              </li>
              <li class="nav-item pt5">
                <a
                  class="nav-link"
                  style="cursor: pointer"
                  v-on:click="$router.push('/about')"
                >
                  <!--<img src="/img/icons/about-icon.png" /> -->About
                </a>
              </li>
              <li class="nav-item pt5">
                <a
                  class="nav-link"
                  style="cursor: pointer"
                  v-on:click="$router.push('/teams')"
                  >
                  <!-- <img src="/img/icons/about-icon.png" /> -->
                   Team
                </a>
              </li>
              <li class="nav-item pt5">
                <a
                  class="nav-link"
                  style="cursor: pointer"
                  v-on:click="$router.push('/pricing')"
                  >
                  <!-- <img src="/img/icons/price.png" width="20" height="20" /> -->
                  Pricing
                </a>
              </li>

              <li class="nav-item pt5">
                <a
                  class="nav-link"
                  style="cursor: pointer"
                  v-on:click="$router.push('/contact')"
                  >
                  <!-- <img src="/img/icons/contact-icon.png" /> -->
                   Contact</a
                >
              </li>
              <!-- <li v-show="token" class="nav-item px-0 notifications">
                <div class="nav-item dropdown" style="cursor: pointer">
                  <a
                    href="#"
                    data-toggle="dropdown"
                    class="nav-link dropdown-toggle"
                  >
                    <img src="/img/notifications.svg" alt="" />
                    <span class="noti-count">3</span>
                  </a>
                  <div class="dropdown-menu">
                    <div class="notifications-item">
                      <img src="/img/avatar-3.jpg" alt="" />
                      <div class="text">
                        <h4>Samso aliao</h4>
                        <p>Samso Nagaro Like your home work</p>
                      </div>
                    </div>
                    <div class="notifications-item">
                      <img src="/img/avatar-4.jpg" alt="" />
                      <div class="text">
                        <h4>John Silvester</h4>
                        <p>+20 vista badge earned</p>
                      </div>
                    </div>
                    <div class="notifications-item">
                      <img src="/img/avatar-5.jpg" alt="" />
                      <div class="text">
                        <h4>Samso aliao</h4>
                        <p>Samso Nagaro Like your home work</p>
                      </div>
                    </div>
                    <div class="text-center">
                      <a
                        href="#"
                        style="
                          color: #f2392a !important;
                          padding-top: 5px;
                          display: inline-block;
                        "
                        >View All</a
                      >
                    </div>
                  </div>
                </div>
              </li> -->
              <li v-show="token" class="nav-item px-0">
                <div class="nav-item dropdown" style="cursor: pointer">
                  <a
                    data-toggle="dropdown"
                    class="nav-link dropdown-toggle user-action"
                  >
                    <img
                      :src="
                        detail.profile_photo
                          ? detail.profile_photo
                          : '/img/user-pic.png'
                      "
                      class="avatar"
                      alt="Avatar"
                      width="35"
                      id="profile"
                      height="35"
                      style="border-radius: 100%" />
                    <b class="caret"></b
                  ></a>
                  <div class="dropdown-menu">
                    <a
                      v-on:click="$router.push('/profile')"
                      class="dropdown-item"
                      ><i class="fa fa-user"></i>&nbsp; Profile</a
                    >
                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item"
                      v-on:click="$router.push('/favourites')"
                    >
                      <i class="fa fa-thumbs-up" aria-hidden="true"></i>

                      &nbsp; My Profiles</a
                    >
                    <div class="dropdown-divider"></div>
                    <a v-on:click="logout()" class="dropdown-item"
                      ><i class="fa fa-sign-in"></i>&nbsp; Logout</a
                    >
                  </div>
                </div>
              </li>
              <li v-show="!token" class="nav-item pt3">
                <a
                  class="nav-link login-btn"
                  style="cursor: pointer"
                  v-on:click="$router.push('/login')"
                  >Login</a
                >
              </li>
              <li v-show="!token" class="nav-item pt3">
                <a
                  class="nav-link login-btn"
                  style="cursor: pointer; width: 139px !important"
                  v-on:click="showRequestDemoModel()"
                  >Request Demo</a
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  </div>
  <TextModal ref="textModal">
    <div
      class="modal-header pt-0 text-center"
      style="
        justify-content: center;
        display: block ruby;
        background-color: black;
        margin-top: -21px;
      "
    >
      <h5 class="modal-title">
        <a class="navbar-brand"
          ><img src="/img/logo.svg" alt="ExecLibrary" width="100" height="51"
        /></a>
      </h5>
      <button
        class="close"
        style="color: white !important"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form ref="requestDemo" @submit="save">
      <div class="col-lg-12 col-sm-12 row">
        <div class="col-lg-6 form-group mb-3 mt-3">
          <label>First Name</label>
          <Field
            type="text"
            name="first_name"
            class="form-control"
            placeholder="First Name"
            v-model="requestDemoObj.first_name"
            rules="required:first name"
            :validateOnInput="true"
          />
          <ErrorMessage name="first_name" class="text-danger" />
        </div>
        <div class="col-lg-6 form-group mb-3 mt-3">
          <label>Last Name</label>
          <Field
            type="text"
            name="last_name"
            class="form-control"
            placeholder="Last Name"
            v-model="requestDemoObj.last_name"
            rules="required:last name"
            :validateOnInput="true"
          />
          <ErrorMessage name="last_name" class="text-danger" />
        </div>

        <div class="col-lg-6 form-group mb-3">
          <label>Email</label>
          <Field
            type="text"
            name="email"
            class="form-control"
            placeholder="Email"
            v-model="requestDemoObj.email"
            rules="required:email|email"
            :validateOnInput="true"
          />
          <ErrorMessage name="email" class="text-danger" />
        </div>
        <div class="col-lg-6 form-group mb-3">
          <label>Contact Number</label>
          <Field
            type="text"
            name="contact"
            class="form-control"
            placeholder="Contact"
            v-on:keypress="$helperService.allowOnlyNumericValue($event)"
            v-model="requestDemoObj.contact"
            rules="required:contact"
            :validateOnInput="true"
          />
          <ErrorMessage name="contact" class="text-danger" />
        </div>
        <div class="col-lg-12 form-group mb-3">
          <label>Company Name</label>
          <Field
            type="text"
            name="company_name"
            class="form-control"
            placeholder="Company Name"
            v-model="requestDemoObj.company_name"
            rules="required:company name"
            :validateOnInput="true"
          />
          <ErrorMessage name="company_name" class="text-danger" />
        </div>
        <div class="col-lg-12 form-group mb-3">
          <label>Note</label>
          <textarea
            type="textarea"
            name="Note"
            class="form-control"
            placeholder="Note"
            v-model="requestDemoObj.note"
          />
          <!-- <ErrorMessage name="last_name" class="text-danger" /> -->
        </div>
      </div>
      <div class="col-lg-12 modal-footer pb-0" style="justify-content: center">
        <button
          class="form-control btn-primary rounded submit px-3 mt-1"
          id="request-button"
          style="width: 45%; border-radius: 5px"
        >
          Request Demo
        </button>
        <!-- <button
          type="button"
          class="fil-btn"
          id="cancel-button"
          v-on:click="$refs.textModal.closeModal"
          style="width: 20%; border-radius: 5px"
        >
          Cancel
        </button> -->
      </div>
    </Form>
  </TextModal>
  <TextModal ref="otpModalNew">
    <div
      class="modal-header pt-0 text-center"
      style="justify-content: center; display: block ruby"
    >
      <h5 class="modal-title">Verfiy You Email</h5>
      <button class="close" v-on:click="$refs.otpModalNew.closeModal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="col-12 row">
      <div class="col-md-12 col-lg-12">
        <div class="login-wrap p-4 pt-4">
          <div class="posAbs mt-3">
            <!-- <router-link to="/forgot-password">
              <a style="color: #000"><i class="fa fa-arrow-left"></i></a>
            </router-link> -->
          </div>
          <div class="form-body">
            <div class="mt-3 col-md-12 col-sm-12 text-center">
              <b>Please Enter the OTP sent On </b>
              <div>
                <div class="pt-1 text-center" style="color: #de4a38">
                  <a>{{ this.requestDemoObj.email }}</a>
                </div>
              </div>
              <div class="text-center my-4">
                <img src="/img/ENTER_OTP.png" alt="" width="100" height="100" />
              </div>
              <Form @submit="verifyOtp()">
                <div class="text-center mb-4 digit-group">
                  <Field
                    id="codeBoxnew1"
                    style="color: black"
                    type="text"
                    name="otp1"
                    class="otp-control"
                    maxlength="1"
                    rules="required"
                    v-on:keyup="onKeyUpEvent(1, $event)"
                    v-on:focus="onFocusEvent(1)"
                  />
                  <Field
                    id="codeBoxnew2"
                    type="text"
                    style="color: black"
                    name="otp2"
                    maxlength="1"
                    rules="required"
                    v-on:keyup="onKeyUpEvent(2, $event)"
                    v-on:focus="onFocusEvent(2)"
                  />
                  <Field
                    id="codeBoxnew3"
                    style="color: black"
                    type="text"
                    name="otp3"
                    maxlength="1"
                    rules="required"
                    v-on:keyup="onKeyUpEvent(3, $event)"
                    v-on:focus="onFocusEvent(3)"
                  />
                  <Field
                    id="codeBoxnew4"
                    type="text"
                    style="color: black"
                    name="otp4"
                    class="otp-control"
                    maxlength="1"
                    rules="required:otp"
                    v-on:keyup="onKeyUpEvent(4, $event)"
                    v-on:focus="onFocusEvent(4)"
                  />
                </div>
                <div class="text-center">
                  <ErrorMessage name="otp4" class="text-danger" />
                </div>
                <div class="mt-2 col-md-6 col-sm-6 mx-auto">
                  <button class="submit-btn" id="verify-otp-button">
                    Submit
                  </button>
                </div>
                <div class="col-mg-12 col-sm-12 text-center">
                  <p class="mb-0 mt-3">
                    <a
                      style="cursor: pointer; color: #de4a38"
                      id="resend-button"
                      v-on:click="resend()"
                      >Resend OTP</a
                    >
                  </p>
                  <hr />
                  <!-- <p class="mb-0 mt-3">
                    <a href="#termsconditionsModal" data-toggle="modal"
                      ><input type="checkbox" class="" id="" /> Accept Terms and
                      Conditions
                    </a>
                  </p> -->
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </TextModal>
</template>
<script>
import TextModal from "@/components/TextModal";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "Header",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
  },
  watch: {
    "$route.fullPath"() {
      this.moveToContacat();
    },
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      detail: {},
      requestDemoObj: {},
    };
  },
  mounted() {
    this.moveToContacat();
    this.getLoginUserDetail();
  },

  methods: {
    verifyOtp() {
      var obj = {};
      obj.email = localStorage.getItem("email");
      obj.id = localStorage.getItem("id");
      obj.otp =
        document.getElementById("codeBoxnew1").value +
        "" +
        document.getElementById("codeBoxnew2").value +
        "" +
        document.getElementById("codeBoxnew3").value +
        "" +
        document.getElementById("codeBoxnew4").value;
      this.$api
        .putAPI({
          _action: "/verify-request-email",
          _body: obj,
          _buttonId: "verify-otp-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.otpModalNew.closeModal();
        })
        .catch(() => {});
    },
    resend() {
      var obj = {};
      obj.email = localStorage.getItem("email");
      obj.id = localStorage.getItem("id");
      this.$api
        .postAPI({
          _action: "/resend-request-demo-otp",
          _body: obj,
          _buttonId: "resend-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
    getCodeBoxElement(index) {
      return document.getElementById("codeBoxnew" + index);
    },
    onKeyUpEvent(index, event) {
      const eventCode = event.which || event.keyCode;
      if (this.getCodeBoxElement(index).value.length === 1) {
        if (index !== 4) {
          this.getCodeBoxElement(index + 1).focus();
        } else {
          this.getCodeBoxElement(index).blur();
        }
      }
      if (eventCode === 8 && index !== 1) {
        this.getCodeBoxElement(index - 1).focus();
      }
    },
    onFocusEvent(index) {
      for (var item = 1; item < index; item++) {
        const currentElement = this.getCodeBoxElement(item);
        if (!currentElement.value) {
          currentElement.focus();
          break;
        }
      }
    },
    showRequestDemoModel() {
      this.$refs.requestDemo.resetForm();
      this.requestDemoObj.note = "";
      this.$refs.textModal.showModal();
    },
    save() {
      this.$api
        .postAPI({
          _action: "/request-demo",
          _body: this.requestDemoObj,
          _buttonId: "request-button",
        })
        .then((res) => {
          localStorage.setItem("email", this.requestDemoObj.email);
          localStorage.setItem("id", res.id);
          this.$refs.textModal.closeModal();
          this.$refs.otpModalNew.showModal();
        })
        .catch(() => {});
    },
    getLoginUserDetail() {
      var token = localStorage.getItem("token");
      if (token) {
        this.$api
          .getAPI({
            _action: "/me",
          })
          .then((res) => {
            if (res) {
              this.detail = res;
              localStorage.setItem('user_detail', JSON.stringify(res))
            }
          })
          .catch(() => {});
      }
    },

    moveToContacat() {
      // alert(document.body.scrollHeight)
      if (window.location.pathname == "/contact") {
        window.scrollTo(0, document.body.scrollHeight);
      } else if (window.location.pathname == "/price") {
        window.scrollTo(0, 2560);
      } else {
        window.scrollTo(0, 0);
      }
    },
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },
  },
};
</script>
